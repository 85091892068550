function buttonTrigger(selector: string, callback: Function) {

	const qs = document.querySelector(selector) as HTMLInputElement;

	if (!qs) return;

	const cl = qs.classList;
	if (cl.contains('hasButtonTrigger')) return;
	cl.add('hasButtonTrigger');

	qs.addEventListener('keyup', (ev: any) => {
		if (ev.key == 'Enter' || ev.key == ' ') callback();
	})

}

export default buttonTrigger;