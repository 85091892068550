<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <ion-page
    ><cyan-page-wrapper :isForm="true">
      <cyan-form>
        <ion-item lines="none">
          <ion-label position="stacked">DUI</ion-label>
          <div class="inputWrapper">
            <ion-input
              type="tel"
              autofocus="true"
              v-model="dui"
              placeholder="12345678-9"
              class="__login-DUI"
            ></ion-input>
            <div class="validationFailed">
              <ion-icon :icon="warningOutline"></ion-icon>
            </div>
          </div>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Contraseña</ion-label>
          <div class="inputWrapper">
            <ion-input
              v-model="password"
              clearOnEdit="false"
              :type="passwordVisible ? 'text' : 'password'"
              class="__login-password"
            ></ion-input>
            <div class="validationFailed">
              <ion-icon :icon="warningOutline"></ion-icon>
            </div>
            <div
              class="eyeIcon"
              v-if="password.length"
              @click="toggleViewPassword"
            >
              <ion-icon
                :icon="passwordVisible ? eyeOffOutline : eyeOutline"
              ></ion-icon>
            </div>
          </div>
        </ion-item>
        <ion-item lines="none">
          <ion-label>Recordar mis datos</ion-label>
          <ion-checkbox v-model="remember"></ion-checkbox>
        </ion-item>

        <ion-button
          class="__login-next"
          expand="block"
          size="large"
          tabindex="0"
          @click="doLogin"
        >
          <span class="button-right-wrapper">Acceder</span>
          <ion-icon slot="end" :icon="arrowForwardCircleOutline"></ion-icon>
        </ion-button>

        <ion-button
          class="dimButton"
          expand="block"
          @click="router.replace('/twoFactorStart')"
        >
          <span class="button-right-wrapper">Crear/Recuperar contraseña</span>
          <ion-icon slot="end" :icon="helpCircleOutline"></ion-icon>
        </ion-button>
      </cyan-form> </cyan-page-wrapper
  ></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import CyanForm from "@/components/CyanForm.vue";
import {
  arrowForwardCircleOutline,
  helpCircleOutline,
  warningOutline,
  eyeOutline,
  eyeOffOutline,
} from "ionicons/icons";
import buttonTrigger from "@/modules/cyanButtonTrigger";

import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonInput,
  IonIcon,
  IonCheckbox,
} from "@ionic/vue";

import ValidatorSet, {
  validateDUI,
  validateRegexp,
} from "@/modules/cyanValidator";

export default defineComponent({
  name: "Login",
  components: {
    CyanPageWrapper,
    CyanForm,
    IonButton,
    IonItem,
    IonPage,
    IonLabel,
    IonIcon,
    IonInput,
    IonCheckbox,
  },
  setup() {
    return {
      arrowForwardCircleOutline,
      helpCircleOutline,
      warningOutline,
      eyeOutline,
      eyeOffOutline,
    };
  },
  computed: {
    router() {
      return router;
    },
  },
  data() {
    return {
      dui: store.state.lastLoggedIn,
      password: "",
      remember: store.state.rememberEnabled,
      validator: null as any,
      passwordVisible: false,
    };
  },
  mounted() {
    this.validator = new ValidatorSet([
      {
        validator: validateDUI,
        element: ".__login-DUI",
        nextElement: ".__login-password",
      },
      {
        validator: validateRegexp,
        element: ".__login-password",
        nextElement: ".__login-next",
        validatorParams: {
          regexp: ".",
          noMatch: "La contraseña no puede estar vacía.",
        },
      },
    ]);

    buttonTrigger(".__login-next", this.doLogin);
  },
  updated() {
    if (this.validator) this.validator.bind();
    buttonTrigger(".__login-next", this.doLogin);
  },
  methods: {
    toggleViewPassword() {
      this.passwordVisible = !this.passwordVisible;
    },
    async doLogin() {
      if (this.validator) {
        if (!(await this.validator.checkWithAlert())) return;
      }

      const d = await cyanRequest("login", {
        isModal: true,
        isPost: true,
        needsPreflight: false, // !this.$data.remember,
        params: {
          name: this.$data.dui,
          password: this.$data.password,
          requestToken: this.$data.remember ? "1" : "",
        },
      });

      if (!d.ok) return;

      store.commit("userLogin", d);
      router.replace("/home");
    },
  },
});
</script>

<style scoped>
</style>